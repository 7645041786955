export enum EventCategories {
  SEARCH_VIN = "search vin_licenseplate",
  CLICKS = "clicks",
  PAGE = "page",
  ECOMMERCE = "ecommerce",
}

export enum EventLabels {
  REDEEM_CREDIT = "redeemCredit",
}

export enum PageTypes {
  PARTNER_LANDING_PAGE = "Partner Landing Page",
  HOMEPAGE = "Homepage",
  REPORTS = "Reports",
  ACCOUNT = "Account",
}

export enum GenericEvents {
  GENERIC_BUTTON = "generic_button",
}

export enum LoginEvents {
  SOCIAL_LOGIN = "login",
}

export enum LoginTypes {
  SOCIAL_LOGIN = "social",
}

export enum PaymentEvents {
  PAYMENT_ERROR_VIEWED_EVENT = "payment_error_viewed",
}

export enum CheckoutEvents {
  CHECKOUT_TRANSACTION = "eec.transaction",
}

export enum EventActions {
  SUCCESS = "success",
  ERROR = "error",
  PRODUCT_SELECTION = "productSelection",
  REPORT_PURCHASE = "reportPurchase",
}

export enum EventActionsLocations {
  PACKAGE = "package",
}

export enum TransactionLabels {
  FIRST_REPORT = "firstReport",
  REDEEM_CREDIT = "redeemCredit",
}

export enum VoucherEventCategory {
  VOUCHER_CODE = "Voucher Code",
}

export enum ButtonEventCategory {
  BUTTON = "button",
  TEXTLINK = "textlink",
}

export type TProduct = {
  id: string;
  name: string;
  category: string;
  variant: number;
  price: string;
  quantity: number;
};

export type TPaymentDetails = {
  id: string;
  revenue: string;
  paymentType?: string;
  reportCombination?: string;
  coupon?: string;
};

export type Product = {
  name: string;
  id: string;
  vinHashed: boolean;
  list: string;
  price: string;
  position?: number;
};

export type TProducts = TProduct[];

export interface IPayload {
  currencyCode: string;
  checkout: {
    actionField?: {
      step: number;
      option?: string;
    };
    purchase?: {
      actionField: TPaymentDetails;
    };
    products: TProducts | Product[];
  };
}

export interface ITransactionPayload {
  currencyCode: string;
  purchase: {
    actionField: TPaymentDetails;
    products: TProducts | Product[];
  };
}

export interface CommonTrackingData {
  singleReportPrice?: string;
  reportDataTotal?: string;
  usData?: string;
  euData?: string;
  nlData?: string;
  reportCountry?: string;
  reportCombination?: string;
  makeModel?: string;
  vin?: string;
  vinHashed?: boolean;
}
